<template>
  <div class="columns c-w-100">
    <div class="column is-7 is-hidden-mobile p-0" style="min-height: 102vh">
      <img :src="require('@/assets/img/hero-section-login.png')" alt="hero-image-login" class="hero-image-login-costum">
    </div>
    <div class="column is-flex is-justify-content-center is-align-items-center my-4">
      <div class="has-text-centered mx-6">
        <img :src="require('@/assets/img/logo-kanggo.png')" alt="logo-kanggo" class="img-hero">
        <p class="averta-bold is-size-3 mt-4">Selamat Datang Kembali</p>
        <p>Silakan login dengan akun Kamu</p>
        <div class="my-4">
          <b-field label="Email" class="has-text-left has-background-white">
            <b-input
              placeholder="Masukkan email kamu"
              icon="email"
              v-model="email"
              @keyup.native.enter="login()"
              @input="email = email.replace(/\s/g, '')"
            ></b-input>
          </b-field>

          <b-field label="Password" class="is-relative mt-2 mb-3 has-text-left">
            <div class="lock-custom">
              <i class="mdi mdi-lock" :class="focusPassword ? 'has-text-dark' :'custom-lock-color' "></i>
            </div>
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              class="password-custom"
              placeholder="Masukkan Password"
              @focus="focusPassword = true"
              @blur="focusPassword = false"
              @input="password = password.replace(/\s/g, '')"
            />
            <div
              class="icon-password is-clickable"
              @click="handleClickPassword"
            >
              <i v-if="showPassword" class="mdi mdi-eye"></i>
              <i v-else class="mdi mdi-eye-off"></i>
            </div>
          </b-field>

          <!-- <b-field label="Password" class="has-text-left custom-password is-relative">
            <b-input type="password" password-reveal  placeholder="Masukkan password" icon="lock" v-model="password" @keyup.native.enter="login()">
            </b-input>

            <div
              class="icon-password is-clickable"
              @click="handleClickPassword"
            >
              <i v-if="showPassword" class="mdi mdi-eye"></i>
              <i v-else class="mdi mdi-eye-off"></i>
            </div>
          </b-field> -->

          <p href="#" class="lupa-password averta-bold is-clickable" @click.prevent="forgotPassword()">Lupa Password?</p>
        </div>
        <div v-if="showNotif" class="is-flex notif p-3">
          <div class="card-attention">
            <span class="averta-bold">!</span>
          </div>
          <p>{{ messageFailedLogin }}</p>
        </div>
        <b-button class="btn-login averta-bold mb-5 mt-5" @click="login()">Masuk</b-button>
        <p class="mt-4 px-6">Dengan login saya menyetujui <span class="text-active averta-bold">Syarat & Ketentuan</span>
          dan <span class="text-active averta-bold">Kebijakan Privasi</span> Kanggo</p>
        <p class="averta-bold">V.2.0.85</p>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { SpinnerIcon } from '../../components/icons'
import { getFirebaseToken } from '@/services/firebase'

export default defineComponent({
  name: 'Login',
  components: {
    SpinnerIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      user: 'auth/user',
      token: 'auth/token'
    })
  },
  watch: {
    isLogin (newVal, oldVal) {
      if (newVal === true) {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  data () {
    return {
      focusPassword: false,
      showNotif: false,
      showPassword: false,
      messageFailedLogin: '',
      typePassword: 'password',
      email: null,
      password: null,
      isLoading: false,
      isFullPage: true,
      branchSelected: {
        branch_id: 1,
        branch: 'none'
      },
      lokasiLogin: [
        { branch_id: 1, branch: 'Q-BIG BSD' },
        { branch_id: 2, branch: 'Fatmawati' },
        { branch_id: 3, branch: 'Gading Serpong' },
        { branch_id: 4, branch: 'Iskandar Bogor' },
        { branch_id: 5, branch: 'Jatimakmur' }
      ]
    }
  },
  methods: {
    handleClickPassword () {
      this.showPassword = !this.showPassword
    },
    login () {
      this.isLoading = true
      if ((this.email === null || this.email === '') && (this.password === null || this.password === '')) {
        this.showNotif = true
        this.isLoading = false
        this.messageFailedLogin = 'Silakan masukkan email dan password terlebih dahulu'
        return false
      }

      if (this.email === null || this.email === '') {
        this.showNotif = true
        this.isLoading = false
        this.messageFailedLogin = 'Silakan masukkan email terlebih dahulu'
        return false
      }

      if (this.password === null || this.password === '') {
        this.showNotif = true
        this.isLoading = false
        this.messageFailedLogin = 'Silakan masukkan password terlebih dahulu'
        return false
      }

      const userData = {
        email: this.email,
        password: this.password,
        url: this.url
      }

      this.$store
        .dispatch('auth/login', userData)
        .then(() => {
          getFirebaseToken()
          this.isLoading = false
          this.$router.push({ path: '/' })
        })
        .catch(error => {
          this.isLoading = false
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    },
    forgotPassword () {
      this.$router.push('/forgot-password')
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_shell_kanggo')
    const auth = JSON.parse(rs)
    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ path: '/' })
    }
  }
})
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

.img-hero {
  width: 40%;
}

.lupa-password {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: flex;
  justify-content: end;
}

.btn-login {
  width: 100%;
  height: 55px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  box-shadow: 6px 10px 25px rgba(237, 90, 52, 0.24);
  border-radius: 90px;
  color: white;
}

.btn-login:hover {
  color: white;
  opacity: 0.9;
}

.btn-login:active {
  color: white;
}

.text-active {
  color: #D9272D;
}

.notif {
  background: #FFF1F1;
  border-radius: 20px;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}

.hero-image-login-costum {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
</style>
